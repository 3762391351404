import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import BaseLayout from "../components/layout"
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase"
import Seo from "../components/seo";
import SocialIcon from "../components/social-icon";
import BreadcrumbTrail from "../components/breadcrumb-trail";
import ArticleImage from "/src/components/article-image";
import HorizontalRule from "/src/components/horizontal-rule";
import NewsletterSignup from "../components/newsletter-signup";

export default class NewsletterIssue extends React.Component  {

  constructor() {
    super();

    this.scrollToTop = this.scrollToTop.bind(this);
    this.changeFontSize = this.changeFontSize.bind(this);
  }

  changeFontSize(fontSize) {
    this.setState({
      fontSize: fontSize
    });
    localStorage.setItem('article-font-size', fontSize)
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const newsletterIssue = this.props.data.newsletterIssueMdx.nodes[0];
    const site = this.props.data.site

    let newsletterList = this.props.data.newsletterMdx.nodes.sort((a, b) => {
      if (a.frontmatter.issue < b.frontmatter.issue) {
        return -1
      }
      return 1;
    })

    let lastNode;
    let previousIssue;
    let nextIssue;
    let nextIssueUnlocked = false;
    for (let node of newsletterList) {
      if (nextIssueUnlocked) {
        nextIssue = node;
        break;
      }
      if (node.frontmatter.issue === newsletterIssue.frontmatter.issue) {
        previousIssue = lastNode;
        nextIssueUnlocked = true;
      }
      lastNode = node;
    }
    const isFirstIssue = newsletterIssue.frontmatter.issue === 1;
    const isLastIssue = newsletterIssue.frontmatter.issue === newsletterList.length;

    const displayIssueCount = 7
    const issuesFromEnd = newsletterList.length - newsletterIssue.frontmatter.issue;
    let lastIssueDisplay;
    let firstIssueDisplay;
    if (issuesFromEnd >= Math.floor(displayIssueCount/2)) {
      lastIssueDisplay = newsletterIssue.frontmatter.issue + Math.floor(displayIssueCount/2);
    } else {
      lastIssueDisplay = newsletterList.length;
    }
    const issuesDisplayAtOrAfterCurrent = (lastIssueDisplay - newsletterIssue.frontmatter.issue) + 1;
    if (issuesDisplayAtOrAfterCurrent < displayIssueCount) {
      firstIssueDisplay = newsletterIssue.frontmatter.issue - (displayIssueCount - issuesDisplayAtOrAfterCurrent);
    }
    if (firstIssueDisplay < 1) {
      firstIssueDisplay = 1;
    }
    if ((lastIssueDisplay - firstIssueDisplay + 1) < displayIssueCount) {
      const issueDeficiency = displayIssueCount - (lastIssueDisplay - firstIssueDisplay + 1);
      const remainingIssuesAfterCurrentLast = newsletterList.length - lastIssueDisplay;
      if (issueDeficiency < remainingIssuesAfterCurrentLast) {
        lastIssueDisplay += issueDeficiency;
      } else {
        lastIssueDisplay += remainingIssuesAfterCurrentLast;
      }
    }

    let paginationNewsletterList = newsletterList.slice(firstIssueDisplay - 1, lastIssueDisplay);

    let seoDescription;
    if (newsletterIssue.frontmatter.seo_description) {
      seoDescription = newsletterIssue.frontmatter.seo_description;
    } else {
      seoDescription = newsletterIssue.frontmatter.summary;
    }

    let fontSizeLayoutClass;
    let cachedFontSize = null;
    if (typeof localStorage !== 'undefined') {
      cachedFontSize = localStorage.getItem('article-font-size')
    }
    if (cachedFontSize === null) {
      cachedFontSize = 'standard';
    }
    if (cachedFontSize === 'standard') {
      fontSizeLayoutClass = 'article-layout--standardFont'
    } else if (cachedFontSize === 'large') {
      fontSizeLayoutClass = 'article-layout--largeFont'
    }

    function pad(num, size) {
        var s = "000000000" + num;
        return s.substr(s.length-size);
    }

    let breadcrumbList = [
      [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'The Storied Scrolls',
          url: '/newsletter/the-storied-scrolls/'
        },
        {
          title: newsletterIssue.frontmatter.title,
        },
      ]
    ]

    return (
      <BaseLayout>
        <Seo title={`Newsletter | ${newsletterIssue.frontmatter.title}`} desc={seoDescription} banner={newsletterIssue.frontmatter.banner_image} pathname={this.props.pageContext.slug} breadcrumbList={breadcrumbList}></Seo>

        <div className="layout-base-w app-p-base">
          <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
          </div>

          <div className="mt-16 md:mt-28 flex justify-start items-start">
            <div className="hidden md:block sticky top-0 pt-12 -mt-7 pr-8 box-content text-center" style={{'width': '140px'}}>
              <h6 className="text-subheader-4 pb-4">Share</h6>
              <div className="border-b-base border-black pb-8">
                <div className="w-full text-mint py-4 flex justify-center items-center">
                  <SocialIcon siteData={site.siteMetadata} icon="twitter" iconLinkType="share" urlLink={newsletterIssue.slug}></SocialIcon>
                </div>
                <div className="w-full text-mint py-4 flex justify-center items-center">
                  <SocialIcon siteData={site.siteMetadata} icon="facebook" iconLinkType="share" urlLink={newsletterIssue.slug}></SocialIcon>
                </div>
                <div className="w-full text-mint py-4 flex justify-center items-center">
                  <SocialIcon siteData={site.siteMetadata} icon="email" iconLinkType="share" urlLink={newsletterIssue.slug}></SocialIcon>
                </div>
                <div className="w-full text-mint py-4 flex justify-center items-center">
                  <SocialIcon siteData={site.siteMetadata} icon="rss" iconLinkType="direct"></SocialIcon>
                </div>
                <div className="w-full text-mint py-4 flex justify-center items-center">
                  <SocialIcon siteData={site.siteMetadata} icon="url-link" iconLinkType="share" urlLink={newsletterIssue.slug}></SocialIcon>
                </div>
              </div>

              <h6 className="text-subheader-4 mt-12">Text</h6>
              <div className="TextSizeSelector w-full flex justify-center items-center py-8">
                <h6 className="text-header-2 text-mint">Aa</h6>
                <div className={`TextSizeSelector_menu bg-offwhite cursor-pointer`}>
                  <div className={`TextSizeSelector_menuItem ${cachedFontSize === 'standard' ? 'TextSizeSelector_menuItem--selected': 'TextSizeSelector_menuItem--unselected'} p-8 flex justify-center items-center hover:bg-lightblue`} onClick={() => this.changeFontSize('standard')} role="presentation">
                    <div>
                      <h6 className="text-header-2">Aa</h6>
                      <h6 className="text-subheader-3">Small</h6>
                    </div>
                  </div>
                  <div className={`TextSizeSelector_menuItem ${cachedFontSize === 'large' ? 'TextSizeSelector_menuItem--selected': 'TextSizeSelector_menuItem--unselected'} p-8 flex justify-center items-center hover:bg-lightblue`} onClick={() => this.changeFontSize('large')} role="presentation">
                    <div>
                      <h6 className="text-header-1">Aa</h6>
                      <h6 className="text-subheader-3">Large</h6>
                    </div>
                  </div>
                </div>
              </div>

              <button className="btn btn-full btn-white mt-4" onClick={this.scrollToTop} onKeyDown={this.scrollToTop}>Back to Top</button>
            </div>

            <div className={`article-layout ${fontSizeLayoutClass} reading-w`} id="articleBodyContainer">

              <div className="reading-w mx-auto mb-14">
                <h1 className="text-header-1 text-mint text-center mt-2 exclude-markdown-styling">The Storied Scrolls</h1>
                <h2 className="text-subheader-1 mb-6 text-center exclude-markdown-styling">Zack Thoutt's Reader Group & Newsletter</h2>
                <ArticleImage classString="mb-12" src="https://media.zackthoutt.com/img/newsletter/newsletter-scroll@2x.png" alt="Newsletter scroll graphic"/>
              </div>

              <Link to={`/newsletter/the-storied-scrolls/`}><h2 className="text-subheader-1 text-mint mb-4 exclude-markdown-styling">Issue #{newsletterIssue.frontmatter.issue} · {newsletterIssue.frontmatter.formatted_published_date}</h2></Link>
              <h2 className="text-header-2 text-mint exclude-markdown-styling mb-8">{newsletterIssue.frontmatter.title}</h2>

              <MDXRenderer>{newsletterIssue.body}</MDXRenderer>

              <div className="mt-28">
                <p className="text-body-1">Enjoy the journey,</p>
                <ArticleImage classString="mb-12 max-w-md w-full" src="https://media.zackthoutt.com/img/newsletter/signature-nopadding.png" alt="Zack Thoutt Signature"/>
              </div>

              <div className="text-mint mt-40">
                <HorizontalRule ruleType="7"></HorizontalRule>
              </div>

            </div>
          </div>

          <div className="my-40">
            <NewsletterSignup textAlign="left"></NewsletterSignup>
          </div>

          <div className="flex justify-between items-center w-full border-t-base border-b-base border-black mt-20">
            <div className="hidden md:block flex-1 text-left">
              {newsletterList.length > 1 && (
                <h6 className="text-subheader-3">Continue Reading</h6>
              )}
            </div>
            <div className="flex-1 flex justify-between md:justify-center items-center">
              {previousIssue && (
                <Link to={`/${previousIssue.slug}`}>
                  <button className="btn btn-skinny btn-white">←</button>
                </Link>
              )}
              {!previousIssue && (
                <div style={{'width': '50px', 'height': '42px'}}></div>
              )}
              <div className="px-8 flex items-center justify-center">
                <h6 className="text-pagination-decoration">{pad(newsletterIssue.frontmatter.issue, 2)} of {pad(newsletterList.length, 2)}</h6>
              </div>
              {nextIssue && (
                <Link to={`/${nextIssue.slug}`}>
                  <button className="btn btn-skinny btn-white">→</button>
                </Link>
              )}
              {!nextIssue && (
                <div style={{'width': '50px', 'height': '42px'}}></div>
              )}
            </div>
            <div className="hidden md:block flex-1 text-right">
              <h6 className="text-subheader-3">Issue {newsletterIssue.frontmatter.issue}</h6>
            </div>
          </div>
        </div>

      </BaseLayout>
    )
  }

}

export const pageQuery = graphql`
  fragment NewsletterFrontmatter on Mdx {
    frontmatter {
      record_type
      title
      published_date(formatString: "YYYY-MM-DD")
      formatted_published_date: published_date(formatString: "MMM Do, YYYY")
      published_year: published_date(formatString: "YYYY")
      banner_image
      published
      issue
      summary
      seo_description
    }
  }

  fragment NewsletterDetails on Mdx {
    id
    slug
    excerpt(pruneLength: 250)
    body
    ...NewsletterFrontmatter
  }

  query($slug: String!) {
    newsletterIssueMdx: allMdx(
      filter: {slug: { eq: $slug }, frontmatter: { published: { eq: true }, record_type: { eq: "newsletter" } } }
    ) {
      nodes {
        ...NewsletterDetails    
      }
    }
    
    newsletterMdx: allMdx(
      sort: { fields: [frontmatter___published_date, frontmatter___issue], order: [DESC, ASC] }
      filter: {frontmatter: { published: { eq: true }, record_type: { eq: "newsletter" } } }
    ) {
      nodes {
        ...NewsletterDetails    
      }
    }
    
    site {
      siteMetadata {
        ...CommonMetaData
      }
    }
  }
`


